/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProfileRequest } from '../models/CreateProfileRequest';
import type { CreateSubscriptionRequest } from '../models/CreateSubscriptionRequest';
import type { JoinOrganizationRequest } from '../models/JoinOrganizationRequest';
import type { OrganizationOnboardingResponse } from '../models/OrganizationOnboardingResponse';
import type { OrganizationProfileResponse } from '../models/OrganizationProfileResponse';
import type { SubscriptionResponse } from '../models/SubscriptionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OnboardingService {

    /**
     * Start onboarding for an organization
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public static createOrganization(
        requestBody: CreateProfileRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/onboarding',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Join Organization
     * @param requestBody
     * @returns OrganizationProfileResponse OK
     * @throws ApiError
     */
    public static joinOrganization(
        requestBody: JoinOrganizationRequest,
    ): CancelablePromise<OrganizationProfileResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/onboarding/join-organization',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Organization Onboarding
     * @param organizationId
     * @returns OrganizationOnboardingResponse OK
     * @throws ApiError
     */
    public static organizationOnboarding(
        organizationId: string,
    ): CancelablePromise<OrganizationOnboardingResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/onboarding/{organizationId}',
            path: {
                'organizationId': organizationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Create Subscription
     * @param requestBody
     * @returns SubscriptionResponse OK
     * @throws ApiError
     */
    public static createSubscription(
        requestBody: CreateSubscriptionRequest,
    ): CancelablePromise<SubscriptionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/onboarding/create-subscription',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Check Workspace is valid
     * @param workspaceId
     * @returns boolean OK
     * @throws ApiError
     */
    public static validateWorkspace(
        workspaceId: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/onboarding/workspace/validate/{workspaceId}',
            path: {
                'workspaceId': workspaceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Complete Onboarding
     * @param organizationId
     * @returns SubscriptionResponse OK
     * @throws ApiError
     */
    public static completeOnboarding(
        organizationId: string,
    ): CancelablePromise<SubscriptionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/onboarding/complete/{organizationId}',
            path: {
                'organizationId': organizationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
