import axios from 'axios'
import { BASE_URL, getTokenFromCookie } from '~/api/instance/utils'

const baseURL = BASE_URL
export const userClientInstance = axios.create({ baseURL })

userClientInstance.interceptors.request.use((config) => {
  if (config.headers) {
    const token = getTokenFromCookie('access_token')
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})
