/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SubscriptionStatus {
    INACTIVE = 'Inactive',
    ACTIVE = 'Active',
    CANCELLED = 'Cancelled',
    SUSPENDED = 'Suspended',
}
