/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DevCreateProductWithSubscriptionRequest } from '../models/DevCreateProductWithSubscriptionRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DevEndpointsService {

    /**
     * @param organizationId
     * @param subscriptionId
     * @param subscriptionStatus
     * @returns any OK
     * @throws ApiError
     */
    public static postDevSubscriptionsStatusSet(
        organizationId: string,
        subscriptionId: string,
        subscriptionStatus: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dev/subscriptions/{subscriptionId}/status/set',
            path: {
                'subscriptionId': subscriptionId,
            },
            query: {
                'organizationId': organizationId,
                'subscriptionStatus': subscriptionStatus,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static postDevProductsCreateWithSubscription(
        requestBody: DevCreateProductWithSubscriptionRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dev/products/create-with-subscription',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
