const isDefined = <T>(value: T | null | undefined): value is Exclude<T, null | undefined> => {
  return value !== undefined && value !== null
}

export const getQueryString = (params: Record<string, any>): string => {
  const qs: string[] = []

  const append = (key: string, value: any) => {
    qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
  }

  const process = (key: string, value: any) => {
    if (isDefined(value)) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          process(key, v)
        })
      } else if (typeof value === 'object') {
        Object.entries(value).forEach(([k, v]) => {
          process(`${key}[${k}]`, v)
        })
      } else {
        append(key, value)
      }
    }
  }

  Object.entries(params).forEach(([key, value]) => {
    process(key, value)
  })

  if (qs.length > 0) {
    return `?${qs.join('&')}`
  }

  return ''
}

export const ORGANIZATIONS_EPS = [
  '/me',
  '/billing/payment-methods/stripe-setup-intent',
  '/billing/payment-methods',
  '/organization',
  '/organizations/invitations',
  '/billing/payment-methods/primary/set',
]
