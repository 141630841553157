import axios from 'axios'
import { BASE_URL, getTokenFromCookie } from '~/api/instance/utils'

const baseURL = BASE_URL
const TOKEN_KEY = 'backoffice_token'
export const backOfficeClientInstance = axios.create({ baseURL })

backOfficeClientInstance.interceptors.request.use((config) => {
  if (config.headers) {
    const token = getTokenFromCookie(TOKEN_KEY)
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})
