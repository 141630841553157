/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackOfficeProfileResponse } from '../models/BackOfficeProfileResponse';
import type { BackOfficeTokenResponse } from '../models/BackOfficeTokenResponse';
import type { CreateBackOfficeAccessTokenRequest } from '../models/CreateBackOfficeAccessTokenRequest';
import type { DetailedOrganizationResponse } from '../models/DetailedOrganizationResponse';
import type { InviteToBackOfficeRequest } from '../models/InviteToBackOfficeRequest';
import type { InvoiceResponsePagedListResponse } from '../models/InvoiceResponsePagedListResponse';
import type { InvoiceStatus } from '../models/InvoiceStatus';
import type { JoinBackOfficeRequest } from '../models/JoinBackOfficeRequest';
import type { OrganizationProfileResponsePagedListResponse } from '../models/OrganizationProfileResponsePagedListResponse';
import type { OrganizationWithSubscriptionResponsePagedListResponse } from '../models/OrganizationWithSubscriptionResponsePagedListResponse';
import type { RevokeBackOfficeInvitationRequest } from '../models/RevokeBackOfficeInvitationRequest';
import type { SubscriptionWithPlanResponsePagedListResponse } from '../models/SubscriptionWithPlanResponsePagedListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BackOfficeService {

    /**
     * Get Back Office Access Token
     * @param requestBody
     * @returns BackOfficeTokenResponse OK
     * @throws ApiError
     */
    public static getBackOfficeAccessToken(
        requestBody: CreateBackOfficeAccessTokenRequest,
    ): CancelablePromise<BackOfficeTokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back-office/token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Join Back Office
     * @param requestBody
     * @returns BackOfficeProfileResponse OK
     * @throws ApiError
     */
    public static joinBackOffice(
        requestBody: JoinBackOfficeRequest,
    ): CancelablePromise<BackOfficeProfileResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/back-office/join',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Invite user to Back Office by email
     * @param requestBody
     * @returns any Created
     * @throws ApiError
     */
    public static inviteToBackOffice(
        requestBody: InviteToBackOfficeRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back-office/invite',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Revoke Back Office Invitation
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static revokeBackOfficeInvitation(
        requestBody: RevokeBackOfficeInvitationRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back-office/revoke',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Remove Back Office Member
     * @param profileId
     * @returns any OK
     * @throws ApiError
     */
    public static removeBackOfficeMember(
        profileId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/back-office/remove/profile/{profileId}',
            path: {
                'profileId': profileId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns BackOfficeProfileResponse OK
     * @throws ApiError
     */
    public static getMyBackOfficeProfile(): CancelablePromise<BackOfficeProfileResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back-office/me',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Getting internal users of Back Office
     * @param pageNumber
     * @param pageSize
     * @param search
     * @param sortBy
     * @param sortDescending
     * @returns OrganizationProfileResponsePagedListResponse OK
     * @throws ApiError
     */
    public static getBackOfficeProfiles(
        pageNumber?: number,
        pageSize?: number,
        search?: string,
        sortBy?: string,
        sortDescending?: boolean,
    ): CancelablePromise<OrganizationProfileResponsePagedListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back-office/profiles',
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'Search': search,
                'SortBy': sortBy,
                'SortDescending': sortDescending,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Getting users of exact organization
     * @param organizationId
     * @param pageNumber
     * @param pageSize
     * @param search
     * @param sortBy
     * @param sortDescending
     * @returns OrganizationProfileResponsePagedListResponse OK
     * @throws ApiError
     */
    public static getSpecificOrganizationProfiles(
        organizationId: string,
        pageNumber?: number,
        pageSize?: number,
        search?: string,
        sortBy?: string,
        sortDescending?: boolean,
    ): CancelablePromise<OrganizationProfileResponsePagedListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back-office/organization/{organizationId}/profiles',
            path: {
                'organizationId': organizationId,
            },
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'Search': search,
                'SortBy': sortBy,
                'SortDescending': sortDescending,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Organization preview data with subscriptions status
     * @param pageNumber
     * @param pageSize
     * @param sortBy
     * @param sortDescending
     * @returns OrganizationWithSubscriptionResponsePagedListResponse OK
     * @throws ApiError
     */
    public static getOrganizationsWithSubscriptions(
        pageNumber?: number,
        pageSize?: number,
        sortBy?: string,
        sortDescending?: boolean,
    ): CancelablePromise<OrganizationWithSubscriptionResponsePagedListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back-office/organizations',
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'SortBy': sortBy,
                'SortDescending': sortDescending,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Detailed Organization by Id
     * @param organizationId
     * @returns DetailedOrganizationResponse OK
     * @throws ApiError
     */
    public static getDetailedOrganization(
        organizationId: string,
    ): CancelablePromise<DetailedOrganizationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back-office/organization/{organizationId}',
            path: {
                'organizationId': organizationId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get Organization Invoices by Id
     * @param organizationId
     * @param pageNumber
     * @param pageSize
     * @param sortBy
     * @param sortDescending
     * @param search
     * @param status
     * @returns InvoiceResponsePagedListResponse OK
     * @throws ApiError
     */
    public static getOrganizationInvoices(
        organizationId: string,
        pageNumber?: number,
        pageSize?: number,
        sortBy?: string,
        sortDescending?: boolean,
        search?: string,
        status?: InvoiceStatus,
    ): CancelablePromise<InvoiceResponsePagedListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back-office/organization/{organizationId}/invoices',
            path: {
                'organizationId': organizationId,
            },
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'SortBy': sortBy,
                'SortDescending': sortDescending,
                'Search': search,
                'status': status,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get Organization Subscriptions by Id
     * @param organizationId
     * @param pageNumber
     * @param pageSize
     * @param sortBy
     * @param sortDescending
     * @returns SubscriptionWithPlanResponsePagedListResponse OK
     * @throws ApiError
     */
    public static getOrganizationSubscriptions(
        organizationId: string,
        pageNumber?: number,
        pageSize?: number,
        sortBy?: string,
        sortDescending?: boolean,
    ): CancelablePromise<SubscriptionWithPlanResponsePagedListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back-office/organization/{organizationId}/subscriptions',
            path: {
                'organizationId': organizationId,
            },
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'SortBy': sortBy,
                'SortDescending': sortDescending,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
