/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrganizationAccessTokenRequest } from '../models/CreateOrganizationAccessTokenRequest';
import type { DetailedOrganizationResponse } from '../models/DetailedOrganizationResponse';
import type { InviteToOrganizationRequest } from '../models/InviteToOrganizationRequest';
import type { ListOrganizationsResponse } from '../models/ListOrganizationsResponse';
import type { OrganizationProfileResponsePagedListResponse } from '../models/OrganizationProfileResponsePagedListResponse';
import type { OrganizationResponse } from '../models/OrganizationResponse';
import type { RevokeInvitationRequest } from '../models/RevokeInvitationRequest';
import type { UpdateOrganizationRequest } from '../models/UpdateOrganizationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationsService {

    /**
     * @returns ListOrganizationsResponse OK
     * @throws ApiError
     */
    public static getMyOrganizations(): CancelablePromise<ListOrganizationsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param organizationId
     * @returns OrganizationResponse OK
     * @throws ApiError
     */
    public static getOrganizations(
        organizationId: string,
    ): CancelablePromise<OrganizationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/{organizationId}',
            path: {
                'organizationId': organizationId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param organizationId
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static getOrganizationAccessToken(
        organizationId: string,
        requestBody: CreateOrganizationAccessTokenRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organizations/{organizationId}/token',
            path: {
                'organizationId': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns OrganizationResponse Created
     * @throws ApiError
     */
    public static postOrganizationsInvitations(
        requestBody: InviteToOrganizationRequest,
    ): CancelablePromise<OrganizationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organizations/invitations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns DetailedOrganizationResponse OK
     * @throws ApiError
     */
    public static getOrganization(): CancelablePromise<DetailedOrganizationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organization',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns DetailedOrganizationResponse OK
     * @throws ApiError
     */
    public static putOrganization(
        requestBody: UpdateOrganizationRequest,
    ): CancelablePromise<DetailedOrganizationResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/organization',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param pageNumber
     * @param pageSize
     * @param search
     * @param sortBy
     * @param sortDescending
     * @returns OrganizationProfileResponsePagedListResponse OK
     * @throws ApiError
     */
    public static getOrganizationProfiles(
        pageNumber?: number,
        pageSize?: number,
        search?: string,
        sortBy?: string,
        sortDescending?: boolean,
    ): CancelablePromise<OrganizationProfileResponsePagedListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organization/profiles',
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'Search': search,
                'SortBy': sortBy,
                'SortDescending': sortDescending,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param profileId
     * @returns any OK
     * @throws ApiError
     */
    public static postOrganizationProfilesRemove(
        profileId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organization/profiles/{profileId}/remove',
            path: {
                'profileId': profileId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns OrganizationResponse Created
     * @throws ApiError
     */
    public static postOrganizationInvitations(
        requestBody: InviteToOrganizationRequest,
    ): CancelablePromise<OrganizationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organization/invitations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns OrganizationResponse Created
     * @throws ApiError
     */
    public static postOrganizationInvitationsRevoke(
        requestBody: RevokeInvitationRequest,
    ): CancelablePromise<OrganizationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organization/invitations/revoke',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
