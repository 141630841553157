import { parseCookies } from 'nookies'

export const BASE_URL = process.env.NEXT_PUBLIC_API_URL

export const API_USER_FEATURES = ['onboarding', 'organizations']
export const API_USER_FEATURES_EXCEPTIONS = ['invitations']

export const getTokenFromCookie = (key: string) => {
  const parsedCookies = parseCookies()
  return parsedCookies[key]
}
