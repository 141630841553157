/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProfileState {
    INVITED = 'Invited',
    ACCEPTED = 'Accepted',
    DEACTIVATED = 'Deactivated',
    DELETED = 'Deleted',
}
