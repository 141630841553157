/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BackOfficeAccessStatus } from '../models/BackOfficeAccessStatus';
import type { OrganizationProfileResponse } from '../models/OrganizationProfileResponse';
import type { UpdateProfileRequest } from '../models/UpdateProfileRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MeService {

    /**
     * Get my organization profile
     * @returns OrganizationProfileResponse OK
     * @throws ApiError
     */
    public static getMyOrganizationProfile(): CancelablePromise<OrganizationProfileResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/me',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Update my profile
     * @param requestBody
     * @returns OrganizationProfileResponse OK
     * @throws ApiError
     */
    public static updateMyProfile(
        requestBody: UpdateProfileRequest,
    ): CancelablePromise<OrganizationProfileResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/me',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Checks if current user has access to Back Office
     * @returns BackOfficeAccessStatus OK
     * @throws ApiError
     */
    public static getBackOfficeAccessStatus(): CancelablePromise<BackOfficeAccessStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/me/back-office',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
