/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InvoiceStatus {
    PAID = 'Paid',
    UNPAID = 'Unpaid',
    OPEN = 'Open',
}
