/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatedSetupIntentResponse } from '../models/CreatedSetupIntentResponse';
import type { CreateStripeSetupIntentRequest } from '../models/CreateStripeSetupIntentRequest';
import type { OrganizationPaymentMethodsResponse } from '../models/OrganizationPaymentMethodsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentMethodsService {

    /**
     * @param refresh
     * @returns OrganizationPaymentMethodsResponse OK
     * @throws ApiError
     */
    public static getBillingPaymentMethods(
        refresh?: boolean,
    ): CancelablePromise<OrganizationPaymentMethodsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/billing/payment-methods',
            query: {
                'refresh': refresh,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns OrganizationPaymentMethodsResponse OK
     * @throws ApiError
     */
    public static postBillingPaymentMethodsPrimarySet(): CancelablePromise<OrganizationPaymentMethodsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/billing/payment-methods/primary/set',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns OrganizationPaymentMethodsResponse OK
     * @throws ApiError
     */
    public static postBillingPaymentMethodsPrimaryRemove(): CancelablePromise<OrganizationPaymentMethodsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/billing/payment-methods/primary/remove',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns OrganizationPaymentMethodsResponse OK
     * @throws ApiError
     */
    public static postBillingPaymentMethodsBackupRemove(): CancelablePromise<OrganizationPaymentMethodsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/billing/payment-methods/backup/remove',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns CreatedSetupIntentResponse Created
     * @throws ApiError
     */
    public static postBillingPaymentMethodsStripeSetupIntent(
        requestBody: CreateStripeSetupIntentRequest,
    ): CancelablePromise<CreatedSetupIntentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/billing/payment-methods/stripe-setup-intent',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
