import { CancelablePromise, OpenAPIConfig } from '~/api/generated'
import { ApiRequestOptions } from '~/api/generated/core/ApiRequestOptions'
import {
  backOfficeClientInstance,
  organizationClientInstance,
  userClientInstance,
} from '~/api/instance'
import { API_USER_FEATURES, API_USER_FEATURES_EXCEPTIONS } from '~/api/instance/utils'
import { getQueryString } from '~/api/utils'

const getUrl = (config: OpenAPIConfig, options: ApiRequestOptions): string => {
  const encoder = config.ENCODE_PATH || encodeURI

  const path = options.url
    .replace('{api-version}', config.VERSION)
    .replace(/{(.*?)}/g, (substring: string, group: string) => {
      if (options.path?.hasOwnProperty(group)) {
        return encoder(String(options.path[group]))
      }
      return substring
    })

  const url = `${config.BASE}${path}`
  if (options.query) {
    return `${url}${getQueryString(options.query)} `
  }
  return url
}

export const request = <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions
): CancelablePromise<T> => {
  return new CancelablePromise((resolve, reject, _onCancel) => {
    const url = getUrl(config, options)

    const axiosInstance = getAxiosInstance(url)

    axiosInstance
      .request({
        url,
        data: options.body,
        method: options.method,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

function getAxiosInstance(url: string) {
  const isBackOffice = url.includes('back-office')
  const feature = url.split('/').filter((x) => x)[0]
  const subFeature = url.split('/').filter((x) => x)[1]

  if (isBackOffice) {
    return backOfficeClientInstance
  }

  return API_USER_FEATURES.includes(feature) && !API_USER_FEATURES_EXCEPTIONS.includes(subFeature)
    ? userClientInstance
    : organizationClientInstance
}
