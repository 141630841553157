/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvoiceResponse } from '../models/InvoiceResponse';
import type { InvoiceResponsePagedListResponse } from '../models/InvoiceResponsePagedListResponse';
import type { InvoiceStatus } from '../models/InvoiceStatus';
import type { PaymentAttemptResponsePagedListResponse } from '../models/PaymentAttemptResponsePagedListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvoicesService {

    /**
     * List invoices
     * SortBy: Amount, Plan, Product, Status, IssuedAt (default)
     * @param pageNumber
     * @param pageSize
     * @param search
     * @param sortBy
     * @param sortDescending
     * @param status
     * @returns InvoiceResponsePagedListResponse OK
     * @throws ApiError
     */
    public static getBillingInvoices(
        pageNumber?: number,
        pageSize?: number,
        search?: string,
        sortBy?: string,
        sortDescending?: boolean,
        status?: InvoiceStatus,
    ): CancelablePromise<InvoiceResponsePagedListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/billing/invoices',
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'Search': search,
                'SortBy': sortBy,
                'SortDescending': sortDescending,
                'status': status,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param id
     * @returns InvoiceResponse OK
     * @throws ApiError
     */
    public static getBillingInvoices1(
        id: string,
    ): CancelablePromise<InvoiceResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/billing/invoices/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id
     * @returns InvoiceResponse OK
     * @throws ApiError
     */
    public static getBillingInvoicesPaymentToken(
        id: string,
    ): CancelablePromise<InvoiceResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/billing/invoices/{id}/payment-token',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * List payment attempts
     * SortBy: Succeeded, CreatedAt, UpdatedAt, PaymentsInvoiceId, InvoiceId, AttemptedAt (default)
     * @param id
     * @param pageNumber
     * @param pageSize
     * @param search
     * @param sortBy
     * @param sortDescending
     * @returns PaymentAttemptResponsePagedListResponse OK
     * @throws ApiError
     */
    public static getBillingInvoicesPaymentAttempts(
        id: string,
        pageNumber?: number,
        pageSize?: number,
        search?: string,
        sortBy?: string,
        sortDescending?: boolean,
    ): CancelablePromise<PaymentAttemptResponsePagedListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/billing/invoices/{id}/payment-attempts',
            path: {
                'id': id,
            },
            query: {
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'Search': search,
                'SortBy': sortBy,
                'SortDescending': sortDescending,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
