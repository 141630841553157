/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProductType {
    INTERCOM = 'Intercom',
    ZENDESK = 'Zendesk',
    KEYSTONE = 'Keystone',
}
